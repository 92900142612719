import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import { IChatSession } from '../../../libs/models/ChatSession';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { ChatListItem } from './ChatListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
    },
    header: {
        margin: '0.625rem 1rem 0.125rem',
        fontWeight: 700,
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
        color: '#526170',
    },
    list: {
        '& > *': {
            ...shorthands.borderBottom('0.3px', 'solid', '#D5D6E7'),
        },
    },
});

export const ChatListSection: React.FC<IChatListSectionProps> = ({ filterText }) => {
    const classes = useClasses();
    const { selectedId, chatSessions } = useAppSelector((state: RootState) => state.conversations);
    const groupedSessions = groupSessions(chatSessions);
    const previousDates = Object.keys(groupedSessions.PreviousDates).sort((a, b) => {
        const dateA = new Date(a.split('/').reverse().join('-')).getTime();
        const dateB = new Date(b.split('/').reverse().join('-')).getTime();
        return dateB - dateA;
    });
    const filterSession = (session: IChatSession, groupText: string) => {
        if (!filterText || groupText.toLowerCase().includes(filterText.toLowerCase())) {
            return true;
        }

        return session.title.toLowerCase().includes(filterText.toLowerCase());
    };

    return chatSessions.length > 0 ? (
        <div className={classes.root}>
            <ChatGroup
                sessions={groupedSessions.Today.filter((session) => filterSession(session, 'Today'))}
                header="Today"
                selectedId={selectedId}
            />
            <ChatGroup
                sessions={groupedSessions.Yesterday.filter((session) => filterSession(session, 'Yesterday'))}
                header="Yesterday"
                selectedId={selectedId}
            />

            {previousDates.map((date) => (
                <ChatGroup
                    sessions={groupedSessions.PreviousDates[date].filter((session) => filterSession(session, date))}
                    header={date}
                    selectedId={selectedId}
                    key={date}
                />
            ))}
        </div>
    ) : null;
};

function ChatGroup({ sessions, header, selectedId }: { sessions: IChatSession[]; header: string; selectedId: string }) {
    const classes = useClasses();
    const sortedSessions = [...sessions].sort((a, b) => {
        const dateA = a.createdOn ? new Date(a.createdOn).getTime() : 0;
        const dateB = b.createdOn ? new Date(b.createdOn).getTime() : 0;
        return dateB - dateA;
    });

    // Add a null check for sessions
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!sessions || sessions.length === 0) {
        return null;
    }

    return (
        <>
            <Text className={classes.header}>{header}</Text>
            <div className={classes.list}>
                {sortedSessions.map((session) => {
                    const isSelected = session.id === selectedId;
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    const messages = session.copilotChatMessages || [];
                    const lastMessage = messages.length > 1 ? messages[0].content : '';
                    return (
                        <ChatListItem
                            id={session.id}
                            key={session.id}
                            isSelected={isSelected}
                            header={session.title}
                            subTitle={lastMessage}
                        />
                    );
                })}
            </div>
        </>
    );
}

function groupSessions(data: IChatSession[]) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);

    const grouped: { Today: IChatSession[]; Yesterday: IChatSession[]; PreviousDates: Record<string, IChatSession[]> } =
        {
            Today: [],
            Yesterday: [],
            PreviousDates: {},
        };

    data.forEach((item) => {
        const date = new Date(item.createdOn ?? 0);
        const dateString = date.toLocaleDateString('en-AU');

        date.setHours(0, 0, 0, 0);

        const isToday = date.toDateString() === today.toDateString();
        const isYesterday = date.toDateString() === yesterday.toDateString();

        if (isToday) {
            grouped.Today.push(item);
        } else if (isYesterday) {
            grouped.Yesterday.push(item);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!grouped.PreviousDates[dateString]) {
                grouped.PreviousDates[dateString] = [];
            }

            grouped.PreviousDates[dateString].push(item);
        }
    });

    return grouped;
}

interface IChatListSectionProps {
    header?: string;
    conversations?: Conversations;
    filterText: string;
}
