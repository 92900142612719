import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
    Button,
    DrawerBody,
    DrawerHeader,
    FluentProvider,
    Image,
    makeStyles,
    mergeClasses,
    OverlayDrawer,
    Persona,
    shorthands,
    Subtitle1,
    tokens,
} from '@fluentui/react-components';

import * as React from 'react';
import { useEffect } from 'react';
import { UserSettingsMenu } from './components/header/UserSettingsMenu';
// import { PluginGallery } from './components/open-api-plugins/PluginGallery';
import { BackendProbe, ChatView, Error, Loading, Login } from './components/views';
import { AuthHelper } from './libs/auth/AuthHelper';
import { useChat, useFile } from './libs/hooks';
import { AlertType } from './libs/models/AlertType';
import { useAppDispatch, useAppSelector } from './redux/app/hooks';
import { resetState, RootState } from './redux/app/store';
import { ActiveUserInfo, FeatureKeys } from './redux/features/app/AppState';
import { addAlert, setActiveUserInfo, setServiceInfo, setView } from './redux/features/app/appSlice';
import { Breakpoints, semanticKernelDarkTheme, semanticKernelLightTheme } from './styles';

import { Dismiss24Regular } from '@fluentui/react-icons';
import { useDispatch } from 'react-redux';
import MainLogoWhite from './assets/bot-icons/logo-white.png';
import MainLogo from './assets/bot-icons/logo.png';
import { AirlieLogo, AirlieLogoWhite, HamburgerMenu } from './assets/icons/icons';
import { AppIcon } from './components/shared/AppIcon';
import { useUser } from './libs/hooks/useUser';
import { setSelectedConversation, showContentInMobile } from './redux/features/conversations/conversationsSlice';

export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
    },
    header: {
        alignItems: 'center',
        backgroundColor: '#fff',
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        '& h1': {
            color: '#0074BF',
        },
        minHeight: '67px',
        justifyContent: 'space-between',
        width: '100%',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
        ...shorthands.paddingInline('16px'),
        boxSizing: 'border-box',
        zIndex: 10,
    },
    persona: {
        marginRight: tokens.spacingHorizontalXXL,
    },
    cornerItems: {
        display: 'flex',
        justifyContent: 'end',
        ...shorthands.flex(1),
        ...shorthands.gap(tokens.spacingHorizontalXXL),
        minWidth: 'fit-content',
    },
    cornerItemsMargin: {
        marginLeft: '24px',
    },
    hideOnMobile: {
        display: 'none',
        '@media (min-width: 768px)': {
            display: 'block',
        },
    },
    hideOnDesktop: {
        '@media (min-width: 768px)': {
            display: 'none',
        },
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...shorthands.flex(1),
    },
    mobileCornerItems: {
        display: 'flex',
        justifyContent: 'end',
        width: '40%',
        ...shorthands.gap('8px'),
    },
    iconButton: {
        minWidth: 0,
        ...shorthands.padding(0),
        color: '#0074BF !important',
    },
    iconButtonDisabled: {
        color: '#C5ECFC !important',
    },
    drawer: {
        ...shorthands.border(0),
    },
    drawerBackground: {
        backgroundColor: '#0074BF',
        backgroundImage: 'none',
    },
    drawerHeader: {
        ...shorthands.paddingBlock(0),
        ...shorthands.paddingInline('16px'),
        height: '67px',
        justifyContent: 'center',
        color: '#fff',
    },
    drawerBody: {
        paddingTop: '60px',
    },
    mobilePersona: {
        '& > span': {
            color: '#fff',
        },
        '& > [class*="Avatar"]': {
            alignSelf: 'center',
            width: '48px',
            height: '48px',
        },
        '& > [class*="primaryText"]': {
            fontWeight: 700,
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
        },
        '& > [class*="secondaryText"]': {
            fontSize: '0.875rem',
            lineHeight: '1.375rem',
            fontWeight: 500,
        },
    },
    mobileNav: {
        listStyleType: 'none',
        paddingLeft: 0,
        ...shorthands.marginBlock(0),
        marginBottom: '70px',
        '& > * + *': {
            marginTop: '20px',
        },
    },
    mobileNavBtn: {
        color: '#fff !important',
        fontSize: '1.75rem',
        lineHeight: '2.5rem',
        fontWeight: 600,
        padding: 0,
        justifyContent: 'flex-start',
        ':hover:active': {
            outline: '1px solid',
            outlineOffset: '0.25rem',
        },
    },
    mobileSignOut: {
        color: '#fff !important',
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '1.5rem',
        marginTop: '1.875rem',
        minWidth: 'fit-content',
        ...shorthands.padding(0),
        ':hover:active': {
            outline: '1px solid',
            outlineOffset: '0.25rem',
        },
    },
    airlieText: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        fontSize: '32px',
        fontWeight: 400,
        ...shorthands.margin(0),
    },
    logo: {
        marginRight: '-0.25rem',
    },
    tempDiv: {
        width: '40%',
        ...Breakpoints.medium({
            flex: 1,
            width: 'auto',
        }),
    },
    headerButtons: {
        color: '#000E2F',
        minWidth: 'fit-content',
        padding: 0,
        fontWeight: 500,
        lineHeight: '1.375rem',
    },
    disabled: {
        opacity: '0.4',
        pointerEvents: 'none',
    },
});

enum AppState {
    ProbeForBackend,
    SettingUserInfo,
    ErrorLoadingChats,
    ErrorLoadingUserInfo,
    LoadingChats,
    Chat,
    SigningOut,
}

const Chat = ({
    classes,
    appState,
    setAppState,
    activeUserInfo,
    onLogout,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
    activeUserInfo: ActiveUserInfo | undefined;
    onLogout: () => void;
}) => {
    const { user } = useUser();
    const onBackendFound = React.useCallback(() => {
        setAppState(AppState.SettingUserInfo);
    }, [setAppState]);

    const {
        selectedId,
        showContentInMobile: showMobileContent,
        view,
    } = useAppSelector((state: RootState) => ({
        ...state.conversations,
        ...state.app,
    }));

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const isNewChatSelected = !view && selectedId === '' && showMobileContent;

    const onNewChat = () => {
        dispatch(setSelectedConversation(''));
        dispatch(showContentInMobile(true));
        dispatch(setView(null));
        setOpen(false);
    };

    const onShowHistory = () => {
        dispatch(showContentInMobile(false));
        setOpen(false);
    };

    const onClickPage = (page: 'help' | 'about') => {
        dispatch(setSelectedConversation(''));
        dispatch(setView(page));
        setOpen(false);
        dispatch(showContentInMobile(true));
    };

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.logoContainer}>
                    <Image alt="Airlie" src={AirlieLogo} height={39} width={39} />

                    <Subtitle1 as="h1" className={classes.airlieText}>
                        <Image src={MainLogo} alt="Airlie Logo" className={classes.logo} />
                        Airlie
                    </Subtitle1>
                </div>

                {appState > AppState.SettingUserInfo ? (
                    <>
                        <div className={mergeClasses(classes.cornerItems, classes.hideOnMobile)}>
                            <div className={mergeClasses(classes.cornerItems, classes.cornerItemsMargin)}>
                                <Button
                                    onClick={() => {
                                        window.open(
                                            'https://allens.sharepoint.com/sites/ChatGPTandGenerativeAI/SitePages/FAQs.aspx#what-do-i-need-to-think-about-when-using-it-%E2%80%93-what-are-the-raapca-rules',
                                            '_blank',
                                        );
                                    }}
                                    appearance="transparent"
                                    className={classes.headerButtons}
                                >
                                    Rules of use
                                </Button>
                                <Button
                                    onClick={() => {
                                        onClickPage('about');
                                    }}
                                    appearance="transparent"
                                    className={classes.headerButtons}
                                >
                                    About
                                </Button>

                                <Button
                                    onClick={() => {
                                        onClickPage('help');
                                    }}
                                    appearance="transparent"
                                    className={classes.headerButtons}
                                >
                                    Help
                                </Button>

                                {/* <PluginGallery /> */}
                                <UserSettingsMenu
                                    user={user}
                                    activeUserInfo={activeUserInfo}
                                    setLoadingState={() => {
                                        setAppState(AppState.SigningOut);
                                    }}
                                />
                            </div>
                        </div>

                        <div className={mergeClasses(classes.hideOnDesktop, classes.mobileCornerItems)}>
                            <OverlayDrawer
                                className={classes.drawer}
                                size="full"
                                position="end"
                                open={open}
                                onOpenChange={(_, state) => {
                                    setOpen(state.open);
                                }}
                            >
                                <DrawerHeader className={mergeClasses(classes.drawerHeader, classes.drawerBackground)}>
                                    <div className={classes.logoContainer}>
                                        <Image alt="Airlie" src={AirlieLogoWhite} height={39} width={39} />

                                        <Subtitle1 className={classes.airlieText}>
                                            <Image src={MainLogoWhite} alt="Airlie Logo" className={classes.logo} />
                                            Airlie
                                        </Subtitle1>

                                        <Button
                                            appearance="transparent"
                                            aria-label="Close"
                                            icon={<Dismiss24Regular style={{ color: '#fff' }} />}
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        />
                                    </div>
                                </DrawerHeader>

                                <DrawerBody className={mergeClasses(classes.drawerBody, classes.drawerBackground)}>
                                    <ul className={classes.mobileNav}>
                                        <li>
                                            <Button
                                                appearance="transparent"
                                                className={classes.mobileNavBtn}
                                                onClick={onNewChat}
                                            >
                                                Start a New Chat
                                            </Button>
                                        </li>

                                        <li>
                                            <Button
                                                appearance="transparent"
                                                className={classes.mobileNavBtn}
                                                onClick={onShowHistory}
                                            >
                                                Chat History
                                            </Button>
                                        </li>

                                        <li>
                                            <Button
                                                onClick={() => {
                                                    onClickPage('about');
                                                }}
                                                appearance="transparent"
                                                className={classes.mobileNavBtn}
                                            >
                                                About Airlie
                                            </Button>
                                        </li>

                                        <li>
                                            <Button
                                                onClick={() => {
                                                    onClickPage('help');
                                                }}
                                                appearance="transparent"
                                                className={classes.mobileNavBtn}
                                            >
                                                Help
                                            </Button>
                                        </li>
                                    </ul>

                                    <Persona
                                        avatar={{
                                            image: {
                                                src: user?.photo,
                                            },
                                        }}
                                        className={classes.mobilePersona}
                                        name={activeUserInfo?.username}
                                        //secondaryText={user?.jobTitle ?? activeUserInfo?.email}
                                        secondaryText={activeUserInfo?.email}
                                        size="large"
                                    />

                                    <div>
                                        <Button
                                            appearance="transparent"
                                            className={classes.mobileSignOut}
                                            onClick={onLogout}
                                        >
                                            Signout
                                        </Button>
                                    </div>
                                </DrawerBody>
                            </OverlayDrawer>

                            <Button
                                appearance="transparent"
                                className={mergeClasses(
                                    classes.iconButton,
                                    isNewChatSelected && classes.iconButtonDisabled,
                                )}
                                onClick={onNewChat}
                            >
                                <AppIcon name="chat" width={39} height={39} />
                            </Button>

                            <Button
                                appearance="transparent"
                                className={classes.iconButton}
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                <Image alt="Hamburger Menu" src={HamburgerMenu} height={39} width={39} />
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className={classes.tempDiv}></div>
                )}
            </div>

            {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />}

            {appState === AppState.SettingUserInfo && (
                <Loading text={'Hang tight while we fetch your information...'} />
            )}

            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user info. Please try signing out and signing back in.'} />
            )}

            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )}

            {appState === AppState.LoadingChats && <Loading text="Loading chats..." />}

            {appState === AppState.Chat && <ChatView />}
        </div>
    );
};

const App = () => {
    const classes = useClasses();

    const [appState, setAppState] = React.useState(AppState.ProbeForBackend);
    const dispatch = useAppDispatch();

    const { instance, inProgress } = useMsal();
    const { activeUserInfo, features, isMaintenance } = useAppSelector((state: RootState) => state.app);
    const isAuthenticated = useIsAuthenticated();

    const chat = useChat();
    const file = useFile();

    const onLogout = () => {
        setAppState(AppState.SigningOut);
        AuthHelper.logoutAsync(instance);
        resetState();
    };

    useEffect(() => {
        if (isMaintenance && appState !== AppState.ProbeForBackend) {
            setAppState(AppState.ProbeForBackend);
            return;
        }

        if (isAuthenticated && appState === AppState.SettingUserInfo) {
            const account = instance.getActiveAccount();
            if (!account) {
                setAppState(AppState.ErrorLoadingUserInfo);
            } else {
                dispatch(
                    setActiveUserInfo({
                        id: `${account.localAccountId}.${account.tenantId}`,
                        email: account.username, // username is the email address
                        username: account.name ?? account.username,
                    }),
                );

                // Privacy disclaimer for internal users
                if (account.username.split('@')[1] === 'allens.com.au') {
                    dispatch(
                        addAlert({
                            message:
                                'You can ask Airlie questions related to client information but do not use Airlie for legal research. By using Airlie you agree that you have read and understand the guidelines for appropriate use and associated risks.',
                            type: AlertType.Info,
                        }),
                    );
                }

                setAppState(AppState.LoadingChats);
            }
        }

        // if we're authenticated and the app state is Chat, and we should load chat history, then load chats
        if ((isAuthenticated || !AuthHelper.isAuthAAD()) && appState === AppState.LoadingChats) {
            void Promise.all([
                chat
                    .loadChats()
                    .then(() => {
                        setAppState(AppState.Chat);
                    })
                    .catch(() => {
                        setAppState(AppState.ErrorLoadingChats);
                    }),

                // Other Promise.all calls remain unchanged
                file.getContentSafetyStatus(),
                chat
                    .getServiceInfo()
                    .then((serviceInfo) => {
                        if (serviceInfo) {
                            dispatch(setServiceInfo(serviceInfo));
                        }
                    })
                    .catch(() => {
                        // Handle error
                    }),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, inProgress, isAuthenticated, appState, isMaintenance]);

    const content = (
        <Chat
            classes={classes}
            activeUserInfo={activeUserInfo}
            appState={appState}
            setAppState={setAppState}
            onLogout={onLogout}
        />
    );

    return (
        <FluentProvider
            className="app-container"
            theme={features[FeatureKeys.DarkMode].enabled ? semanticKernelDarkTheme : semanticKernelLightTheme}
        >
            {AuthHelper.isAuthAAD() ? (
                <>
                    <UnauthenticatedTemplate>
                        <div className={classes.container}>
                            <div className={classes.header}>
                                <Subtitle1 as="h1">Airlie</Subtitle1>
                            </div>
                            {appState === AppState.SigningOut && <Loading text="Signing you out..." />}
                            {appState !== AppState.SigningOut && <Login />}
                        </div>
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>{content}</AuthenticatedTemplate>
                </>
            ) : (
                content
            )}
        </FluentProvider>
    );
};

export default App;
