import { makeStyles, shorthands } from '@fluentui/react-components';
import { EmblaOptionsType } from 'embla-carousel';
import React from 'react';

import { Breakpoints } from '../../styles';
import EmblaCarousel from '../embla-carousel';

const useClasses = makeStyles({
    root: {
        ...shorthands.padding('30px', '0'),
        boxSizing: 'border-box',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    contentGroup: {
        ...shorthands.padding('0', '1rem'),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 'fit-content',
        width: '100%',
        maxWidth: '50.5rem',
    },
    suggestionText: {
        ...shorthands.margin('0'),
        marginBottom: '4rem',
        textAlign: 'center',
        color: '#0E1E54',
        '& > h2': {
            fontSize: '2.5rem',
            lineHeight: 'normal',
            marginTop: '0',
            marginBottom: '1.125rem',
        },
        '& > p': {
            ...shorthands.margin('auto'),
            maxWidth: '14.6rem',
            textAlign: 'center',
            fontSize: '1.125rem',
        },
        ...Breakpoints.xsmallMinMax({
            marginBottom: '2rem',
            '& > h2': {
                fontSize: '2.25rem',
            },
            '& > p': {
                fontSize: '1rem',
            },
        }),
    },
});

const useStyles = makeStyles({
    suggestionList: {
        width: '100%',
    },
});

interface ChatSuggestionProps {
    onSuggestionClick: (k: string, t: string) => void;
}

const _CHAT_SUGGESTIONS: string[][] = [
    [
        'industry/topic',
        'Guide me on how to research developments in industry/topic. Provide a step-by-step approach and how to organise the findings for a team meeting',
    ],
    [
        'issue',
        'Engage with me in a simulated client conversation where the client is concerned about issue, so I can practice addressing their questions and providing clear explanations',
    ],
    [
        'project',
        'Help me brainstorm and outline a strategic plan for project, including objectives, key steps, potential obstacles, and solutions to overcome them',
    ],
];

const OPTIONS: EmblaOptionsType = {
    align: 'start',
    dragFree: true,
};

export const ChatSuggestion: React.FC<ChatSuggestionProps> = ({ onSuggestionClick }) => {
    const classes = useClasses();
    const styles = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.contentGroup}>
                <div className={classes.suggestionText}>
                    <h2>How can I help?</h2>
                    <p>I can assist with a wide range of tasks. Try asking:</p>
                </div>
                <div className={styles.suggestionList}>
                    <EmblaCarousel slides={_CHAT_SUGGESTIONS} options={OPTIONS} onClickItem={onSuggestionClick} />
                </div>
            </div>
        </div>
    );
};
