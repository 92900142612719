export function AppIcon({ name, ...props }: AppIconProps) {
  return (
    <svg {...props}>
        <use href={`/icons.svg#${name}`} />
    </svg>
  )
}

export interface AppIconProps extends React.SVGProps<SVGSVGElement> {
  name: string;
}