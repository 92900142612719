import { useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

import { editConversationInput } from '../../redux/features/conversations/conversationsSlice';

interface UseChatInputProps {
    suggestion: string;
    suggestionKey: string;
}

const useChatInput = ({ suggestion = '', suggestionKey = '' }: UseChatInputProps) => {
    const chatInputRef = useRef<HTMLTextAreaElement>(null);
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [value, setValue] = useState('');

    const dispatch = useAppDispatch();

    const handleChangeValue = (inputtedValue = '') => {
        setValue(inputtedValue);
        if (selectedId) {
            dispatch(editConversationInput({ id: selectedId, newInput: inputtedValue }));
        }
    };

    const handleClearInput = () => {
        setValue('');
        if (selectedId) {
            dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        }
    };

    useEffect(() => {
        // Focus on the text area when the selected conversation changes
        if (chatInputRef.current) {
            chatInputRef.current.focus();

            if (suggestionKey && suggestion) {
                if (selectedId) {
                    dispatch(editConversationInput({ id: selectedId, newInput: suggestion || '' }));
                }
                setValue(suggestion || '');

                // set the curosr after the value is set in the input field
                setTimeout(() => {
                    const suggestionIndex = suggestion.indexOf(suggestionKey);
                    if (chatInputRef.current) {
                        chatInputRef.current.style.height = `${chatInputRef.current.scrollHeight}px`;
                    }
                    chatInputRef.current?.setSelectionRange(suggestionIndex, suggestionIndex + suggestionKey.length);
                }, 0);
            }
        }
    }, [dispatch, selectedId, suggestion, suggestionKey]);

    return {
        chatInputRef,
        value,
        setValue,
        handleChangeValue,
        handleClearInput,
    };
};

export default useChatInput;
