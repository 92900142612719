import { Image, makeStyles, mergeClasses, shorthands, Text, tokens } from '@fluentui/react-components';
import { FC, TouchEvent, useRef, useState } from 'react';
import { Chat } from '../../../assets/icons/icons';
import { useChat } from '../../../libs/hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys } from '../../../redux/features/app/AppState';
import { setSelectedConversation, showContentInMobile } from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints, SharedStyles, Transition } from '../../../styles';
import { EditChatName } from '../shared/EditChatName';
import { ACTION_BUTTON_WIDTH, ACTION_ITEMS_COUNT, ListItemActions } from './ListItemActions';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        cursor: 'pointer',
        ...shorthands.padding('16px'),
        minHeight: '48px',
        position: 'relative',
        ...Transition('transform'),
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: '1rem',
        color: '#000E2F',
        fontWeight: 600,
        lineHeight: '1.5rem',
    },
    subtitle: {
        ...SharedStyles.overflowEllipsis,
        fontSize: '0.75rem',
        color: '#526170',
        fontWeight: 500,
        lineHeight: '1rem',
    },
    selectedTitle: {
        paddingRight: '87px',
        ...Breakpoints.xsmallMinMax({
            paddingRight: '0',
        }),
        ...Breakpoints.mediumMinMax({
            paddingRight: '37%',
        }),
    },
    selected: {
        ...Breakpoints.medium({
            backgroundColor: '#F2F7FC',
        }),
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    messagePreview: {
        ...SharedStyles.overflowEllipsis,
        color: '#526170',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    border: {
        display: 'none',
        ...Breakpoints.medium({
            display: 'block',
            width: '5px',
            height: '48px',
            position: 'absolute',
            backgroundColor: '#0074BF',
            ...shorthands.borderRadius('8px'),
            left: '9px',
        }),
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        gap: '10px',
    },
});

interface IChatListItemProps {
    id: string;
    header: string;
    isSelected: boolean;
    subTitle?: string;
}

export const ChatListItem: FC<IChatListItemProps> = ({ id, header, isSelected, subTitle }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [startX, setStartX] = useState(0);
    const [currentX, setCurrentX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragged, setDragged] = useState(false);
    const contentElement = contentRef.current;

    const translateContentElement = (pageX: number, adjustOnDrag?: boolean) => {
        if (!contentElement) {
            return;
        }

        const negativeActionButtonWidth = ACTION_BUTTON_WIDTH * -ACTION_ITEMS_COUNT;
        const translateX =
            pageX < negativeActionButtonWidth
                ? `${negativeActionButtonWidth}px`
                : `${adjustOnDrag && pageX < 0 ? pageX : '0'}px`;

        contentElement.style.transform = `translateX(${translateX})`;
    };

    const handleStart = (x: number): void => {
        setStartX(x);
        setDragged(false);
    };

    const handleMove = (x: number): void => {
        if (!startX) {
            return;
        }

        setIsDragging(true);
        setDragged(true);
        setCurrentX(x);

        translateContentElement(x - startX, true);
    };

    const handleEnd = (): void => {
        if (!isDragging) {
            return;
        }

        const deltaX = currentX - startX;

        translateContentElement(deltaX);

        setIsDragging(false);
        setStartX(0);
    };

    const handleTouchStart = (e: TouchEvent<HTMLDivElement>): void => {
        handleStart(e.touches[0].pageX);
    };

    const handleTouchMove = (e: TouchEvent<HTMLDivElement>): void => {
        handleMove(e.touches[0].pageX);
    };

    const handleTouchEnd = handleEnd;

    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();
    const { features } = useAppSelector((state: RootState) => state.app);
    const { conversations } = useAppSelector((state: RootState) => state.conversations);

    const showActions = features[FeatureKeys.SimplifiedExperience].enabled;

    const [editingTitle, setEditingTitle] = useState(false);

    const handleClick = () => {
        if (dragged) {
            setDragged(false);
            return;
        }

        dispatch(showContentInMobile(true));

        if (Object.keys(conversations).includes(id)) {
            dispatch(setSelectedConversation(id));
        } else {
            void chat.loadChat(id);
        }
    };

    return (
        <div
            className={mergeClasses(classes.root, isSelected && classes.selected)}
            onClick={handleClick}
            ref={contentRef}
            title={`Chat: ${header}`}
            aria-label={`Chat list item: ${header}`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className={classes.contentContainer}>
                {isSelected && <div className={classes.border}></div>}

                <Image alt="Chat" src={Chat} />

                {editingTitle ? (
                    <EditChatName
                        name={header}
                        chatId={id}
                        exitEdits={() => {
                            setEditingTitle(false);
                        }}
                    />
                ) : (
                    <>
                        <div className={classes.body}>
                            <div className={classes.header}>
                                <Text
                                    className={mergeClasses(classes.title, isSelected ? classes.selectedTitle : '')}
                                    title={header}
                                >
                                    {header}
                                </Text>
                                <Text
                                    className={mergeClasses(classes.subtitle, isSelected ? classes.selectedTitle : '')}
                                    title={subTitle}
                                >
                                    {subTitle}
                                </Text>
                            </div>
                        </div>

                        {showActions && (
                            <ListItemActions
                                name={header}
                                chatId={id}
                                isSelected={isSelected}
                                onEditTitleClick={() => {
                                    setEditingTitle(true);
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
