import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import { AuthHelper } from '../auth/AuthHelper';
import { UserProfileService } from '../services/UserProfileService';

export interface UserProfile {
    displayName: string;
    //jobTitle: string;
    photo: string;
}

export const useUser = () => {
    const { instance, inProgress } = useMsal();

    const [user, setUser] = useState<UserProfile>();

    useEffect(() => {
        const getUserData = async () => {
            const userProfileService = new UserProfileService();
            const graphAccessToken = await AuthHelper.getGraphAccessToken(instance, inProgress);

            const response = await userProfileService.getUserProfile(graphAccessToken);
            
            const responsePhoto = await userProfileService.getUserPhoto(graphAccessToken) as Blob;

            const reader = new FileReader();

            reader.onloadend = () => {
              const base64data = reader.result;
              setUser({
                  displayName: response.displayName,
                  //jobTitle: response.jobTitle,
                  photo: base64data as string
              });
            };

            reader.readAsDataURL(responsePhoto);

            setUser({
                displayName: response.displayName,
                //jobTitle: response.jobTitle,
                photo: ''
            });
        };

        getUserData().catch((err) => {
            const error = err as Error;
            console.error(error);
        });
    }, [instance, inProgress]);

    return {
        user,
    };
};
