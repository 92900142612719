import { GraphService } from './GraphService';

export interface UserProfileResponseBody {
    displayName: string;
    //jobTitle: string;
}

export class UserProfileService extends GraphService {
    public getUserProfile = async (accessToken: string) => {
        //https://graph.microsoft.com/v1.0/me

        const response = await this.makeRequest<UserProfileResponseBody>('me', accessToken);

        return response;
    };

    public getUserPhoto = async (accessToken: string) => {
        //https://graph.microsoft.com/v1.0/me/photo/$value

        const response = await this.makeRequest('me/photo/$value', accessToken);

        return response;
    };

    private makeRequest = async <T>(resourcePath: string, accessToken: string) => {
        const result = await this.getResponseAsync<T>(
            {
                commandPath: this.getCommandPath(resourcePath),
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
}
