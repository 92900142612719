import { makeStyles } from '@fluentui/react-components';
import { AppIcon } from './AppIcon';

const useClasses = makeStyles({
    list: {
        padding: '0',
        margin: '0',
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',

        '& > li': {
            margin: '0',
            padding: '0',
        },
    },
    item: {
        boxSizing: 'border-box',
        padding: '1rem',
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        textDecoration: 'none',
        backgroundColor: '#fff',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
    },
    iconContainer: {
        boxSizing: 'border-box',
        display: 'block',
        padding: '0.5rem',
        borderRadius: '4px',
        backgroundColor: '#F2F7FC',
        flex: '0 0 3.125rem',
        height: '3.125rem',
        maxHeight: '3.125rem',
        color: '#0074BF',
    },
    labelContainer: {
        boxSizing: 'border-box',
        fontSize: '1.125rem',
        fontWeight: '600',
        lineHeight: '1.5rem',
        color: '#000E2F',
        flex: '1',
        width: '100%',
    },
});

interface ListProps {
    children: React.ReactNode;
}

interface ListItemProps {
    label: string;
    link: string;
    icon: string;
}

const ListItem = ({ label, link, icon }: ListItemProps) => {
    const classes = useClasses();

    return (
        <li>
            <a href={link} className={classes.item} target="_blank" rel="noreferrer">
                <span className={classes.iconContainer}>
                    <AppIcon name={icon} width="100%" height="100%" />
                </span>
                <span className={classes.labelContainer}>{label}</span>
            </a>
        </li>
    );
};

const List = ({ children }: ListProps) => {
    const classes = useClasses();

    return <ul className={classes.list}>{children}</ul>;
};

export { ListItem };
export default List;
