import { makeStyles, shorthands } from '@fluentui/react-components';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useChat } from '../../libs/hooks';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { updateBotResponseStatus } from '../../redux/features/conversations/conversationsSlice';
import { SharedStyles } from '../../styles';
import { ChatInput } from '../chat/ChatInput';
import { ChatSuggestion } from '../chat/ChatSuggestion';
import { Loading } from './Loading';

const useClasses = makeStyles({
    content: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: '1.5rem',
        ...SharedStyles.windowBackground,
    },
    loadingContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        ...shorthands.margin('auto'),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
});

const Landing: React.FC = () => {
    const classes = useClasses();
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [suggestion, setSuggestion] = useState('');
    const [suggestionKey, setSuggestionKey] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const chat = useChat();

    const handleSuggestion = (key: string, label: string) => {
        setSuggestion(label);
        setSuggestionKey(key);
    };

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(true);
    };
    const onDragLeave = (e: React.DragEvent<HTMLDivElement | HTMLTextAreaElement>) => {
        e.preventDefault();
        setIsDraggingOver(false);
    };

    const handleSubmit = async (
        value: string,
        messageType: ChatMessageType = ChatMessageType.Message,
        onClear: () => void,
    ) => {
        if (value.trim() === '') return;
        onClear();
        setLoading(true);
        const chatId = await chat.createChat();
        setLoading(false);
        if (chatId) {
            dispatch(updateBotResponseStatus({ chatId, status: 'Calling the kernel' }));
            await chat.getResponse({ value, messageType, chatId });
        }
    };

    return (
        <div className={classes.content} onDragEnter={onDragEnter} onDragOver={onDragEnter} onDragLeave={onDragLeave}>
            {loading ? (
                <div className={classes.loadingContainer}>
                    <Loading text="Creating Chat..." />
                </div>
            ) : (
                <ChatSuggestion onSuggestionClick={handleSuggestion} />
            )}
            <div className={classes.input}>
                <ChatInput
                    isDraggingOver={isDraggingOver}
                    onDragLeave={onDragLeave}
                    onSubmit={handleSubmit}
                    suggestion={suggestion}
                    suggestionKey={suggestionKey}
                />
            </div>
        </div>
    );
};

export default Landing;
