import { makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';

import { Breakpoints, SharedStyles } from '../../styles';
import Landing from './Landing';
import { Loading } from './Loading';
import { AboutPage, HelpPage } from './SinglePage';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
        width: '100%',
        ...Breakpoints.medium({
            width: 'auto',
        }),
        ...SharedStyles.windowBackground,
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...SharedStyles.windowBackground,
    },
    content: {
        width: '100%',
        display: 'flex',
        ...shorthands.flex(1),
        ...Breakpoints.medium({
            width: 'auto',
        }),
    },
    hideInMobile: {
        display: 'none',
        ...Breakpoints.medium({
            display: 'flex',
        }),
    },
});

export const ChatView: FC = () => {
    const classes = useClasses();
    const { selectedId, status, showContentInMobile, view } = useAppSelector((state: RootState) => ({
        ...state.conversations,
        ...state.app,
    }));
    const isExistingChatSelected = selectedId !== '';

    const content = () => {
        if (status === 'pending') {
            return (
                <div className={classes.loading}>
                    <Loading text={'Hang tight while we fetch your chat...'} />
                </div>
            );
        }

        if (isExistingChatSelected) {
            return <ChatWindow />;
        } else if (view !== null) {
            if (view === 'help') {
                return <HelpPage />;
            } else {
                return <AboutPage />;
            }
        } else {
            return <Landing />;
        }
    };

    return (
        <div className={mergeClasses(classes.container)}>
            <ChatList className={showContentInMobile ? classes.hideInMobile : ''} />

            <div
                key={selectedId}
                className={mergeClasses(classes.content, showContentInMobile ? '' : classes.hideInMobile)}
            >
                {content()}
            </div>
        </div>
    );
};
