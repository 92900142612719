import { Button } from '@fluentui/react-button';
import { Input, InputOnChangeData, Tooltip, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useState } from 'react';
import { useChat } from '../../../../libs/hooks';
import { getFriendlyChatName } from '../../../../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { editConversationTitle } from '../../../../redux/features/conversations/conversationsSlice';
import { Breakpoints } from '../../../../styles';
import { AppIcon } from '../../../shared/AppIcon';

const useClasses = makeStyles({
    root: {
        maxWidth: '518px',
        padding: '1.5rem 1rem',
        ...Breakpoints.max(550, {
            marginInline: '1rem',
        }),
    },
    dialogTitle: {
        color: '#000E2F',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 700,
        alignItems: 'center',
        marginBottom: '0.5rem',

        '& > span': {
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
        },
    },
    input: {
        ...shorthands.padding('0.625rem', '0.875rem'),
        ...shorthands.borderRadius('4px', '0', '0', '4px'),
        fontSize: '1rem',
        width: '100%',
        height: '50px',
        boxSizing: 'border-box',
        '&:hover, &:focus-within, &:active': {
            border: '1px solid #D5D6E7 !important',
        },
        '& input': {
            padding: '0',
            color: '#000E2F',
        },
        ':after': {
            display: 'none',
        },
        border: '1px solid #D5D6E7',
        borderRadius: '4px',
    },
    actions: {
        paddingTop: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        ...Breakpoints.xsmallMinMax({
            flexDirection: 'row',
        }),
    },
    editButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 0,
        height: '100%',
        minWidth: '71px',
        background: '#526170',
        color: '#fff',
        ':hover': {
            color: '#fff',
            background: '#526170b3',
        },
        ...Breakpoints.medium({
            flex: 1,
            display: 'block',
            padding: '0',
            minWidth: '24px',
            background: 'transparent !important',
            color: '#526170',
            ':hover': {
                color: '#0074bf',
            },
            '& span': {
                display: 'none',
            },
        }),
    },
});

const useButtonClasses = makeStyles({
    button: {
        border: '1px solid #D9DCE0',
        padding: '0.875rem',
        maxWidth: '144px',
        minWidth: '144px',
        width: '100%',
        borderRadius: '4px',
        lineHeight: '0.7',
        fontSize: '1rem',
    },
    primary: {
        '--colorBrandBackground': '#0074BF',
        '--colorBrandBackgroundHover': '#006bb1',
        '--colorBrandBackgroundPressed': '#005c98',
        border: 0,
    },
});

interface IEditChatNameProps {
    chatId: string;
    name?: string;
}

export const EditChatDialog: React.FC<IEditChatNameProps> = ({ name, chatId }) => {
    const classes = useClasses();
    const btnClasses = useButtonClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();

    const { chatSessions, conversations } = useAppSelector((state) => state.conversations);
    const chatName = name ? name : getFriendlyChatName(conversations[chatId]);
    const [chatTitle = '', setChatTitle] = useState<string | undefined>(chatName);

    const onSaveTitleChange = async () => {
        if (chatName !== chatTitle) {
            const chatSession = chatSessions.find((session) => session.id === chatId);
            await chat
                .editChat(
                    chatId,
                    chatTitle,
                    chatSession?.systemDescription ?? '',
                    chatSession?.memoryBalance ?? 0.5,
                    chatSession?.copilotChatMessages ?? [],
                )
                .then(() => {
                    dispatch(editConversationTitle({ id: chatId, newTitle: chatTitle }));
                });
        }
    };

    const handleChangeChatTitle = (_ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setChatTitle(data.value);
    };

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Edit Chat Title'} relationship="label">
                    <Button appearance="transparent" aria-label="Edit Chat Title" className={classes.editButton}>
                        <div style={{ color: 'inherit' }}>
                            <AppIcon name="edit" width={24} height={24} />

                            <span>Rename</span>
                        </div>
                    </Button>
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle className={classes.dialogTitle}>
                        <span>Rename Chat</span>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="transparent"
                                icon={<AppIcon name="close" width="100%" height="100%" />}
                            />
                        </DialogTrigger>
                    </DialogTitle>
                    <DialogContent>
                        <Input className={classes.input} value={chatTitle} onChange={handleChangeChatTitle} />
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button className={btnClasses.button} appearance="secondary">
                                Cancel
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                className={mergeClasses(btnClasses.button, btnClasses.primary)}
                                appearance="primary"
                                onClick={() => {
                                    void onSaveTitleChange();
                                }}
                            >
                                Save Changes
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
