import { makeStyles } from '@fluentui/react-components';
import { Breakpoints } from '../../styles';
import List, { ListItem } from '../shared/List';

const useClasses = makeStyles({
    container: {
        maxWidth: '780px',
        width: '100%',
        margin: '0 auto',
        padding: '2.5rem',
        background: 'transparent',
        ...Breakpoints.xsmallMinMax({
            padding: '1.5rem 1rem',
        }),
    },
});

export interface SinglePageProps {
    children: React.ReactNode;
}

export const HelpPage = () => {
    return (
        <SinglePage>
            <List>
                <ListItem
                    label="Frequently Asked Questions"
                    link="https://allens.sharepoint.com/sites/ChatGPTandGenerativeAI/SitePages/FAQs.aspx"
                    icon="chat-add"
                />
                <ListItem label="Feedback" link="https://forms.office.com/r/GUAWx1S90Q" icon="horn" />
            </List>
        </SinglePage>
    );
};

export const AboutPage = () => {
    return (
        <SinglePage>
            <List>
                <ListItem
                    label="Rules of Use"
                    link="https://allens.sharepoint.com/sites/ChatGPTandGenerativeAI/SitePages/FAQs.aspx#what-do-i-need-to-think-about-when-using-it-%E2%80%93-what-are-the-raapca-rules"
                    icon="sticker"
                />
                <ListItem
                    label={`What’s New?`}
                    link="https://allens.sharepoint.com/:u:/r/sites/ChatGPTandGenerativeAI/SitePages/New-feature-log.aspx"
                    icon="lightbulb"
                />
            </List>
        </SinglePage>
    );
};

const SinglePage = ({ children }: SinglePageProps) => {
    const classes = useClasses();

    return <div className={classes.container}>{children}</div>;
};

export default SinglePage;
