import { Button } from '@fluentui/react-button';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { ErrorCircleRegular } from '@fluentui/react-icons';
import { Tooltip } from '@fluentui/react-tooltip';
import React, { useState } from 'react';
import { COPY } from '../../../assets/strings';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Breakpoints } from '../../../styles';
import { InvitationCreateDialog } from '../invitation-dialog/InvitationCreateDialog';
import { DeleteChatDialog } from './dialogs/DeleteChatDialog';
import { EditChatDialog } from './dialogs/EditChatDialog';

export const ACTION_BUTTON_WIDTH = 71;
export const ACTION_ITEMS_COUNT = 2;

const useClasses = makeStyles({
    root: {
        display: 'contents',
    },

    actionItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        insetBlock: 0,
        right: `${ACTION_BUTTON_WIDTH * -ACTION_ITEMS_COUNT}px`,
        aspectRatio: 1,
        minWidth: `${ACTION_BUTTON_WIDTH * ACTION_ITEMS_COUNT}px`,
        borderRadius: 0,
        color: '#fff',
        ...Breakpoints.medium({
            display: 'none',
            justifyContent: 'end',
            right: 0,
            minWidth: `${24 * ACTION_ITEMS_COUNT}px`,
            paddingRight: '1rem',
        }),
    },
    flex: {
        ...Breakpoints.medium({
            display: 'flex',
        }),
    },
});

interface IListItemActionsProps {
    chatId: string;
    onEditTitleClick: () => void;
    name: string;
    isSelected: boolean;
}

export const ListItemActions: React.FC<IListItemActionsProps> = ({
    name,
    chatId,
    isSelected /*, onEditTitleClick*/,
}) => {
    const classes = useClasses();
    // const { features } = useAppSelector((state: RootState) => state.app);
    const { conversations } = useAppSelector((state: RootState) => state.conversations);

    // const chat = useChat();
    // const { downloadFile } = useFile();
    const [isGettingInvitationId, setIsGettingInvitationId] = useState(false);

    // const onDownloadBotClick = useCallback(() => {
    //     // TODO: [Issue #47] Add a loading indicator
    //     void chat.downloadBot(chatId).then((content) => {
    //         downloadFile(
    //             `chat-history-${chatId}-${new Date().toISOString()}.json`,
    //             JSON.stringify(content),
    //             'text/json',
    //         );
    //     });
    // }, [chat, chatId, downloadFile]);

    const stopPropagation = (event: React.SyntheticEvent) => {
        event.stopPropagation();
    };

    return (
        <div className={classes.root} onClick={stopPropagation} onMouseDown={stopPropagation}>
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {conversations[chatId]?.disabled ? (
                <Tooltip content={COPY.CHAT_DELETED_MESSAGE()} relationship="label">
                    <Button
                        icon={<ErrorCircleRegular />}
                        appearance="transparent"
                        aria-label="Alert: Chat has been deleted by another user."
                    />
                </Tooltip>
            ) : (
                <>
                    {/* <Tooltip content={'Edit chat name'} relationship="label">
                        <Button
                            icon={<Edit />}
                            appearance="transparent"
                            aria-label="Edit chat name"
                            onClick={onEditTitleClick}
                            data-testid="editChatTitleButtonSimplified"
                        />
                    </Tooltip>
                    <Tooltip content={'Download chat session'} relationship="label">
                        <Button
                            disabled={!features[FeatureKeys.BotAsDocs].enabled}
                            icon={<ArrowDownload16 />}
                            appearance="transparent"
                            aria-label="Download chat session"
                            onClick={onDownloadBotClick}
                        />
                    </Tooltip>
                    <Tooltip content={'Share live chat code'} relationship="label">
                        <Button
                            disabled={!features[FeatureKeys.MultiUserChat].enabled}
                            icon={<Share20 />}
                            appearance="transparent"
                            aria-label="Share live chat code"
                            onClick={() => {
                                setIsGettingInvitationId(true);
                            }}
                        />
                    </Tooltip> */}

                    <div className={mergeClasses(classes.actionItems, isSelected && classes.flex)}>
                        <EditChatDialog name={name} chatId={chatId} />

                        <DeleteChatDialog name={name} chatId={chatId} />
                    </div>

                    {isGettingInvitationId && (
                        <InvitationCreateDialog
                            onCancel={() => {
                                setIsGettingInvitationId(false);
                            }}
                            chatId={chatId}
                        />
                    )}
                </>
            )}
        </div>
    );
};
