import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    shorthands,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';

export const DEFAULT_FONT_BASE = `'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif`;

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#020303',
    20: '#151719',
    30: '#21262A',
    40: '#2B3238',
    50: '#353E46',
    60: '#404B55',
    70: '#4B5865',
    80: '#576673',
    90: '#667380',
    100: '#75818C',
    110: '#75818C',
    120: '#CF869A',
    130: '#D898A8',
    140: '#E0AAB7',
    150: '#E8BCC6',
    160: '#EFCFD6',
};

export const semanticKernelLightTheme: Theme & Record<string, string | number> = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#e8ebf9',
    timestamp: '#526170',
    colorBrandStroke1: '#0074BF',
    colorBrandStroke2Contrast: '#b4d6fa',
    fontFamilyBase: `'TheSansB', ${DEFAULT_FONT_BASE}`,
};

export const semanticKernelDarkTheme: Theme & Record<string, string | number> = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#2b2b3e',
    timestamp: '#526170',
    colorBrandStroke1: '#0074BF',
    colorBrandStroke2Contrast: '#b4d6fa',
    fontFamilyBase: `'TheSansB', ${DEFAULT_FONT_BASE}`,
};

export const customTokens = themeToTokensObject(semanticKernelLightTheme);

export const Breakpoints = {
    xsmallMinMax: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (min-width: 320px) and (max-width: 512px)': style };
    },
    mediumMinMax: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (min-width: 768px) and (max-width: 1024px)': style };
    },
    xsmall: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (min-width: 512px)': style };
    },
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (min-width: 640px)': style };
    },
    medium: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (min-width: 768px)': style };
    },
    large: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (min-width: 1024px)': style };
    },
    maxXsmall: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 512px)': style };
    },
    maxMedium: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 768px)': style };
    },
    maxLarge: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 1024px)': style };
    },
    min: (width: number, style: GriffelStyle): Record<string, GriffelStyle> => {
        return { [`@media (min-width: ${width}px)`]: style };
    },
    max: (width: number, style: GriffelStyle): Record<string, GriffelStyle> => {
        return { [`@media (max-width: ${width}px)`]: style };
    },
};

export const Transition: (properties: string) => GriffelStyle = (properties) => {
    const transitionProperty = properties;
    const transitionTimingFunction = 'cubic-bezier(0.4, 0, 0.2, 1)';
    const transitionDuration = '150ms';

    return {
        transitionProperty,
        transitionTimingFunction,
        transitionDuration,
    };
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'auto',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'hidden',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
    overflowEllipsis: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    windowBackground: {
        background: 'linear-gradient(182deg, #EFFAFF -11.63%, #A3D2F0 106.42%)',
    },
};

export const useSharedClasses = makeStyles({
    informativeView: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('80px'),
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXL),
        marginTop: tokens.spacingVerticalXXXL,
    },
});

export const useDialogClasses = makeStyles({
    surface: {
        padding: '1rem',
        maxWidth: '811px',
        fontFamilyBase: `'TheSansB', ${DEFAULT_FONT_BASE}`,
        inset: '0.5rem',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
        width: '100%',
    },
    paragraphs: {
        marginTop: tokens.spacingHorizontalS,
    },
    innerContent: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
    text: {
        whiteSpace: 'pre-wrap',
        textOverflow: 'wrap',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
