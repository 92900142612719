import { Card, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';
//import { ChatAlt } from '../../assets/icons/icons';
import { Breakpoints } from '../../styles';
import { DotButton, useDotButton } from './embla-carousel-dot-button';

interface PropType {
    slides: string[][];
    options?: EmblaOptionsType;
    onClickItem: (j: string, k: string) => void;
}

const useCardStyles = makeStyles({
    card: {
        ...shorthands.padding('1rem'),
        borderRadius: '4px',
        minHeight: '255px',
        //minWidth: '255px',
        width: '100%',
        height: 'auto',
        marginBottom: '10px',
        overflowWrap: 'normal',
        rowGap: '0',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        //display: 'flex',
        //flexDirection: 'column',
        alignItems: 'center', // Center align items
        '&:hover': {
            backgroundColor: '#fff',
        },
        ...Breakpoints.xsmallMinMax({
            minHeight: '225px',
        }),
    },
    icon: {
        backgroundColor: '#F2F7FC',
        ...shorthands.padding('0.375rem'),
        borderRadius: '4px',
        marginBottom: '0.5rem',
        display: 'flex',
        alignSelf: 'flex-start',
    },
    title: {
        ...shorthands.margin('0', '0', '1.25rem'),
        color: '#0074BF',
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    text: {
        ...shorthands.margin(0),
        color: '#000E2F',
        '& > span': { color: '#0074BF', fontWeight: 'bold' },
        fontSize: '1rem',
        lineHeight: '1.625rem',
        marginTop: '0.5rem',
        textAlign: 'center',
    },
});

const EmblaCarousel: React.FC<PropType> = (props) => {
    const { slides, options, onClickItem } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options);

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

    const cardStyles = useCardStyles();

    return (
        <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map(([key, label], index) => (
                        <div className="embla__slide" key={`chat-susggestion-${key}-${index}`}>
                            <div className="embla__slide__number">
                                <Card
                                    className={mergeClasses('embla__slide', cardStyles.card)}
                                    onClick={() => {
                                        onClickItem(key, label);
                                    }}
                                >
                                    <p className={cardStyles.title}>Ask Airlie</p>
                                    <p
                                        className={cardStyles.text}
                                        dangerouslySetInnerHTML={{
                                            __html: label.replace(key, `<span>${key}</span>`),
                                        }}
                                    ></p>
                                </Card>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="embla__controls">
                <div className="embla__dots">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => {
                                onDotButtonClick(index);
                            }}
                            className={'embla__dot'.concat(index === selectedIndex ? ' embla__dot--selected' : '')}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default EmblaCarousel;
