import { GraphService } from './GraphService';
import { SharePointSettings } from './SharePointSettingsService';

export interface SharePointListResponseBody {
    value: SharePointListResponse[];
}

export interface SharePointListResponse {
    fields: {
        Title: string;
        Prompt: string;
    };
}

export class SharePointListService extends GraphService {
    public getLists = async (accessToken: string, { siteId, sitePath, listId }: SharePointSettings) => {
        //https://graph.microsoft.com/v1.0/sites/sodadigitalptyltd-my.sharepoint.com:/personal/chad_sodadigital_com_au:/lists/2dfbe27f-dacc-422d-a532-5745f5aca234

        const response = await this.makeRequest(
            `sites/${siteId}:${sitePath}:/lists/${listId}/items?expand=fields($select=Title,Prompt)&$filter=fields/System eq 'Airlie'&select=id`,
            accessToken,
        );

        return response;
    };

    private makeRequest = async (resourcePath: string, accessToken: string) => {
        const result = await this.getResponseAsync<SharePointListResponseBody>(
            {
                commandPath: this.getCommandPath(resourcePath),
                method: 'GET',
                // note: Provide the 'Prefer: HonorNonIndexedQueriesWarningMayFailRandomly' header to allow this,
                // but be warned that such queries may fail on large lists.
                addedHeaders: [['Prefer', 'HonorNonIndexedQueriesWarningMayFailRandomly']],
            },
            accessToken,
        );

        return result;
    };
}
