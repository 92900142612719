import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useChat } from '../../../../libs/hooks';
import { getFriendlyChatName } from '../../../../libs/hooks/useChat';
import { useAppSelector } from '../../../../redux/app/hooks';
import { Breakpoints } from '../../../../styles';
import { AppIcon } from '../../../shared/AppIcon';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
    deleteButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        insetBlock: 0,
        minWidth: '71px',
        height: '100%',
        borderRadius: 0,
        background: '#A90A00',
        color: '#fff',
        ':hover': {
            color: '#fff',
            background: '#a90a00b3',
        },
        ...Breakpoints.medium({
            flex: 1,
            display: 'block',
            padding: '0',
            minWidth: '24px',
            background: 'transparent !important',
            color: '#526170',
            ':hover': {
                color: '#0074bf',
            },
            '& span': {
                display: 'none',
            },
        }),
    },
});

interface IEditChatNameProps {
    chatId: string;
    name?: string;
}

export const DeleteChatDialog: React.FC<IEditChatNameProps> = ({ name, chatId }) => {
    const classes = useClasses();
    const chat = useChat();

    const { conversations } = useAppSelector((state) => state.conversations);
    const chatName = name ? name : getFriendlyChatName(conversations[chatId]);

    const onDeleteChat = () => {
        void chat.deleteChat(chatId, name);
    };

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete chat session'} relationship="label">
                    <Button appearance="transparent" aria-label="Delete Chat" className={classes.deleteButton}>
                        <div style={{ color: 'inherit' }}>
                            <AppIcon name="bin" width={24} height={24} />

                            <span>Delete</span>
                        </div>
                    </Button>
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to delete chat: {chatName}?</DialogTitle>
                    <DialogContent>
                        This action will permanently delete the chat, and any associated resources and memories, for all
                        participants, including Airlie.
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" onClick={onDeleteChat}>
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
